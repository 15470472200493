import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Badge, Button} from 'react-bootstrap';
import RoleDelete from "./RoleDelete";
import deleteIcon from "../../assets/images/icons/actions/delete.svg";
import editIcon from "../../assets/images/icons/actions/edit.svg";
import "react-datepicker/dist/react-datepicker.css";
import {useGlobalConfigStore} from "../../stores/globalConfigStore";
import roleIcon from '../../assets/images/icons/role-icon.svg';
import {useNavigate} from "react-router-dom";
import inPlusIcon from "../../assets/images/icons/plus-in-button-icon.svg";
import FlashMessage from "../common/FlashMessage";

const RoleList = observer(() => {
    const store = useGlobalConfigStore();
    const navigate = useNavigate();
    const maxVisible = 3;

    const [selectedModel, setSelectedModel] = useState(null);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState(null);
    const [variant, setVariant] = useState('success');

    useEffect(() => {
        store.loadData();
    }, []);

    const handleDelete = async () => {
        if (selectedModel) {
            setIsSubmitting(true);
            try {
                await store.deleteModel(selectedModel.id);
                setShowEditModal(false);
                setMessage('Success!');
                setVariant('success');
            } catch (error) {
                console.error('Failed to delete model:', error);
                setMessage(`Error!: ${store.errors}`);
                setVariant('danger');
            } finally {
                setIsSubmitting(false);
                setShowDeleteModal(false);
                setSelectedModel(null);
            }
        }
    };

    const handleDeleteClick = (model) => {
        if (!store.loading) {
            setSelectedModel(model);
            setShowDeleteModal(true);
        }
    };

    const handleEditClick = (item) => {
        navigate(`/global-config/new/${item.id ?? ''}`);
        setShowEditModal(true);
    };

    const handleCloseModal = () => {
        setShowDetailModal(false);
        setShowEditModal(false);
        setShowDeleteModal(false);
        setSelectedModel(null);
    };

    const handleAddClick = () => {
        navigate('/global-config/new');
    }

    const resetErrorMessage = () => {
        setMessage(null)
        store.resetErrorsStore(false);
    }

    return (
        <>
            <div className={'wrap-title-page d-flex align-content-center justify-content-between'}>
                <h2 className={'page-title mb-3'}>Global config</h2>
                <Button className={'button-custom button-blue'}
                        onClick={handleAddClick}><img src={inPlusIcon}/> Add role</Button>
            </div>
            <div className={'relative'}>
                <div className={'d-flex flex-wrap'}>
                    {store.data.map((item) => (
                        <div key={item.id} className={'block-role-item'}>
                            <div className={'d-flex align-items-center'}>
                                <div className={'wrap-icon-role'}>
                                    <img src={roleIcon} alt="Icon"/>
                                </div>
                                <div>{item.name}</div>
                            </div>
                            <div className={'d-flex justify-content-between align-items-center'}>
                                <div className={'role-icons-container'}>
                                    {item?.users?.map((itemUser, index) => (
                                        <div
                                            key={itemUser.id}
                                            className="role-icon"
                                            style={{left: `${index * 12}%`}}
                                        >
                                            <img
                                                src={itemUser?.userImage || roleIcon}
                                                alt="Icon"
                                                onError={(e) => {
                                                    e.target.src = roleIcon;
                                                }}
                                            />
                                        </div>
                                    ))}
                                    {item?.remainingUsersCount > 0 && (
                                        <div className="role-icon" style={{left: `${maxVisible * 12}%`}}>
                                            <Badge className={'additional-roles-block'}>
                                                +{item?.remainingUsersCount}
                                            </Badge>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <div className={'wrap-button-row d-flex no-wrap'}>
                                        <img className={'me-2'} src={deleteIcon} alt="Delete"
                                             onClick={() => handleDeleteClick(item)}/>
                                        <img src={editIcon} alt="Edit"
                                             onClick={() => handleEditClick(item)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <RoleDelete
                show={showDeleteModal}
                onClose={handleCloseModal}
                onDelete={handleDelete}
                isSubmitting={isSubmitting}
            />
            <FlashMessage message={message} variant={variant} onClose={() => resetErrorMessage()}/>
            {!message &&
                <FlashMessage message={store.errors} variant={'danger'} onClose={() => resetErrorMessage()}/>
            }
        </>
    );
});
export default RoleList;
